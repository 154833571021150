import { useNavigate } from '@remix-run/react';
import uniq from 'lodash/uniq';
import { useFieldArray, useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { EMAIL_PATTERN } from '../components/Access';
import { Loading } from '../components/Loading';
import {
  OnboardingModalLayout,
  OnboardingPageLayout,
} from '../components/Onboarding/OnboardingLayout';
import { OrgRequired, OrgUtils } from '../components/Organization';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService, type InvitedUser } from '../services/api-service';
import { type Organization } from '../types';
import { getStaticAssetPath } from '../utils/assets';
import { err2s } from '../utils/common';

export interface FormData {
  members: {
    email: string;
  }[];
}

export function Container(props: { organization: Organization }) {
  const { organization } = props;

  const analytics = useOnboardingAnalytics();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setFocus,
  } = useForm<FormData>({
    defaultValues: {
      members: [{ email: '' }, { email: '' }],
    },
  });
  const { fields, append, update } = useFieldArray<FormData, 'members'>({
    control,
    name: 'members',
  });

  const {
    call: onSubmit,
    state: { error },
  } = useLiveAsyncCall(
    handleSubmit(async (data) => {
      const emails = uniq(
        data.members.map((m) => m.email.toLowerCase()).filter((e) => !!e)
      );

      analytics.trackOnboardingAddMembers({
        emails,
        count: emails.length,
      });

      if (emails.length > 0) {
        const invitedUsers = emails.map<InvitedUser>((email) => ({
          email,
        }));

        if (OrgUtils.IsSlackActive(organization.connection)) {
          const resp = await apiService.slack.queryUsers({
            orgId: organization.id,
            emails: emails.join(','),
            type: 'byEmails',
          });
          for (const invitedUser of invitedUsers) {
            const exUser = resp.data.users.find(
              (u) => u.email.toLowerCase() === invitedUser.email.toLowerCase()
            );
            if (exUser) {
              invitedUser.exUserId = exUser.id;
              invitedUser.fullName = exUser.fullName;
            }
          }
        }

        await apiService.organization.inviteOrganizers(organization.id, {
          invitedUsers,
          webEndpoint: window.location.origin,
          sendEmail: true,
        });
      }

      const params = new URLSearchParams(window.location.search);
      const redirectTo = params.get('redirect-to');
      if (!redirectTo) {
        navigate('/home');
      } else {
        params.delete('redirectTo');
        navigate({
          pathname: redirectTo,
          search: params.toString(),
        });
      }
    })
  );

  useEffectOnce(() => {
    setFocus('members.0.email');
  });

  return (
    <OnboardingPageLayout progress={70}>
      <OnboardingModalLayout>
        <form
          className='w-full px-6 flex flex-col items-center'
          onSubmit={onSubmit}
        >
          <img
            src={getStaticAssetPath('images/onboarding/invite-members.png')}
            alt=''
            className='w-50 h-50 object-contain'
          />

          <h2 className='text-2xl font-medium text-tertiary'>
            Let's Get Started!
          </h2>
          <p className='mt-2.5 px-5 text-base font-bold text-white text-center'>
            Invite a teammate to try it out together
          </p>

          <div className='mt-7.5 w-full flex flex-col gap-2'>
            {fields.map((field, index) => {
              const err = errors.members?.[index]?.email;

              return (
                <div key={index}>
                  <input
                    className={`w-full h-12.5 ${
                      err ? 'field-error' : 'field'
                    } mb-0`}
                    placeholder={`Teammate's email`}
                    maxLength={60}
                    {...register(`members.${index}.email`, {
                      required: false,
                      maxLength: 60,
                      pattern: EMAIL_PATTERN,
                      value: field.email,
                      onChange: (e) => {
                        const email = e.target.value;
                        update(index, { ...field, email });
                        if (
                          fields.length < 5 &&
                          index === fields.length - 1 &&
                          !!email
                        ) {
                          append({ email: '' }, { shouldFocus: false });
                        }
                      },
                    })}
                  />

                  <p className='mt-1 ml-1 h-3 text-3xs text-red-002'>
                    {err && `Please enter a valid email address`}
                  </p>
                </div>
              );
            })}
          </div>

          <div className='mt-4.5 text-3xs font-medium text-icon-gray'>
            You can add more team members later!
          </div>

          <p className='mt-3 h-3 text-sms text-red-002 text-left'>
            {err2s(error)}
          </p>
          <button
            type='submit'
            className='mt-1 w-full h-15 btn-primary flex justify-center items-center gap-2'
            disabled={isSubmitting}
          >
            {isSubmitting && <Loading text='' />}
            Continue
          </button>
        </form>
      </OnboardingModalLayout>
    </OnboardingPageLayout>
  );
}

export function Component() {
  return (
    <OrgRequired>
      {(organization) => <Container organization={organization} />}
    </OrgRequired>
  );
}
